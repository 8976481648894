<template>
  <div class="w-full">
    <div v-if="hasModuleAccess">
      <div class="">
        <Card class="p-5 pt-0">
          <CardFooter
            reloadcard
            @searchResult="handleSearch($event)"
            showcalendar
            @reload="reload()"
          />
        </Card>
      </div>
      <div class="w-full h-full p-3" />
      <div style="height: 100%" v-if="isLoading">
        <loader size="xxs" :loader-image="false" />
      </div>
      <div v-else>
        <Table
          :headers="header"
          :items="tableData"
          v-if="tableData.length > 0 || loadingTable"
          aria-label="advance Table"
          class="w-full px-0"
          :has-checkbox="false"
          :loading="loadingTable"
          :has-number="false"
          :pagination-list="metaData"
          :page-sync="true"
          @page="handlePage($event)"
          @itemsPerPage="handleItemsPerPage($event)"
        >
          <template v-slot:item="{ item }">
            <div
              v-if="item.year"
              class="font-semibold text-sm leading-5 tracking-widest"
            >
              <span class="text-darkPurple">{{ item.data.year }}</span>
            </div>
            <div
              v-else-if="item.description"
              class="font-semibold text-sm leading-5 tracking-widest"
            >
              <span class="text-darkPurple">{{ item.data.description }}</span>
            </div>
            <div
              v-else-if="item.employees"
              class="font-semibold text-sm leading-5 tracking-widest"
            >
              <div class="flex flex-col justify-start items-start">
                <span class="text-darkPurple">{{ item.data.employees }}</span>
              </div>
            </div>
            <div v-else-if="item.status">
              <Badge
                :label="item.data.status"
                variant="primary"
                :background-color="
                  item.data.status === 'active'
                    ? 'rgba(19, 181, 106, 0.15)'
                    : 'rgba(135, 142, 153, 0.15)'
                "
                :color="item.data.status === 'active' ? '#13B56A' : '#878E99'"
                class="capitalize"
                style="
                  margin-right: 12px;
                  font-size: 14px;
                  padding: 12px 10px;
                  height: 35px;
                  border-radius: 5px;
                "
              />
            </div>
            <div v-else-if="item.id" class="cursor-pointer">
              <Menu left @click.stop="" class="-mr-10">
                <template v-slot:title>
                  <Icon icon-name="more_icon" size="xs" />
                </template>
                <template>
                  <div
                    class="flex flex-col w-38 h-18 p-2 justify-start items-start"
                  >
                    <div
                      class="hover:bg-ghostWhite w-full rounded-md"
                      @click="
                        $router.push({
                          name: 'PerformanceOrganisationAppraisal',
                          params: {
                            year: item.data.year,
                            cycleId: item.data.cycleId,
                            cycleName: item.data.cycleName,
                          },
                        })
                      "
                    >
                      <div class="flex justify-start items-center p-2 gap-2">
                        <Icon
                          icon-name="icon-eye"
                          class-name="text-blueCrayola"
                          size="xs"
                        />
                        <span
                          class="text-darkPurple text-sm leading-5 font-normal"
                        >
                          View
                        </span>
                      </div>
                    </div>
                    <div class="hover:bg-ghostWhite w-full rounded-md">
                      <div
                        class="flex justify-start items-center p-2 gap-2"
                        @click="extendAppraisal()"
                      >
                        <Icon
                          icon-name="maximize"
                          class-name="text-carrotOrange"
                          size="xs"
                        />
                        <span
                          class="text-darkPurple text-sm leading-5 font-normal"
                        >
                          Extensions
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
              </Menu>
            </div>
          </template>
        </Table>

        <div v-else>
          <div class="w-full flex flex-col justify-center items-center mt-20">
            <icon icon-name="empty_agreement" size="l" style="width: 300px" />
            <div class="w-1/2 text-base text-center">
              No performance appraisal displayed.
            </div>
          </div>
        </div>
      </div>

      <ValidationObserver rules="required" v-slot="{ handleSubmit }">
        <RightSideBar
          v-if="windowModal"
          @close="closeWindow"
          submit="Send"
          @submit="handleSubmit(windowExtension)"
          :button-class="`bg-dynamicBackBtn text-white`"
        >
          <template v-slot:title>
            <div class="w-full flex">
              <p class="text-darkPurple mt-3">Appraisal Extension</p>
            </div>
          </template>

          <template v-slot:subtitle>
            <div class="flex flex-col w-full mb-3">
              <p class="my-2 text-base">
                This action enables you extend specific review deadline for all
                employees. Select the review cycle and a new submission deadline
                to complete.
              </p>
            </div>
          </template>

          <div class="grid grid-cols-12 gap-7" style="margin-top: 1.125rem">
            <div class="col-span-12">
              <div class="text-sm text-jet">Review Cycle</div>
              <CSelect
                placeholder="--Select Cycle--"
                class="w-full"
                :options="flagOptions"
                @input="selected($event)"
                :rules="['required']"
                v-model="payload.appraisalCycleId"
              />
            </div>

            <div class="col-span-12">
              <div class="text-sm text-jet">Extend To</div>
              <div
                class="date-flex mt-1"
                :style="endDateError ? 'border : 1px solid red' : ''"
              >
                <datepicker
                  placeholder="--Select Day & Month & Year--"
                  style="width: 100%; outline: none"
                  input-class="date-input"
                  @selected="endDateSelect"
                  v-model="payload.endDate"
                  :disabled-dates="cycleDisableDates"
                />
              </div>
              <small
                data-v-d5a084b0=""
                class="mt-1 text-red-700 text-xs font-semibold"
                v-if="endDateError"
                >End Date is not valid.</small
              >
            </div>

            <div class="col-span-12">
              <div class="text-sm text-jet">Comments</div>
              <CTextarea
                placeholder="--Message--"
                variant="primary"
                class="mt-2"
                :style="commentError ? 'border : 1px solid red' : ''"
                value=""
                :row="8"
                :height="90"
                :col="30"
                @input="commentView($event)"
                v-model="payload.comment"
              />
              <small
                data-v-d5a084b0=""
                class="mt-1 text-red-700 text-xs font-semibold"
                v-if="commentError"
                >Comment Field is not valid.</small
              >
            </div>
          </div>
        </RightSideBar>
      </ValidationObserver>
    </div>
    <div v-else>
      <ErrorComponent />
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Datepicker from "vuejs-datepicker";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import Menu from "@scelloo/cloudenly-ui/src/components/Menu";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area";
import CardFooter from "@/components/CardFooter";
import RightSideBar from "@/components/RightSideBar";
import paramsMixin from "@/utilities/paramsMixin";
import timeFormatMixin from "@/utilities/timeFormatMixin";
import ErrorComponent from "@/modules/Admin/error403";

export default {
  name: "TDirectReports",
  mixins: [paramsMixin, timeFormatMixin],
  components: {
    ValidationObserver,
    ErrorComponent,
    Datepicker,
    CTextarea,
    Table,
    Card,
    CardFooter,
    RightSideBar,
    CSelect,
    Menu,
    Badge,
  },
  data() {
    return {
      header: [
        { title: "financial year", value: "year" },
        { title: "description", value: "description" },
        { title: "Period", value: "periodStart" },
      ],
      tableData: [],
      hasModuleAccess: false,
      isLoading: true,
      employeeLength: "",
      appraisalsArr: [],
      cyclesArr: [],
      shortTermGoals: [],
      agreementData: [],
      windowModal: false,
      endDateError: false,
      commentError: false,
      flagOptions: [],
      metaData: {},
      itemsPerPage: null,
      numberOfPage: null,
      cycleId: "",
      payload: {
        orgId: "",
        appraisalCycleId: "",
        year: "",
        operation: "appraisal",
        comment: "",
        endDate: "",
        cycleId: "",
      },
      cycleDisableDates: {
        from: "",
        to: "",
      },
      loadingTable: true,
      searchQuery: "",
    };
  },
  methods: {
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },

    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },

    queryUpdate() {
      this.loadingTable = true;
      const search = this.searchQuery ? `&${this.searchQuery}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getOrganizationGoal(`?${pageNumber}${itemPage}${search}`);
    },

    handleSearch(value) {
      this.searchQuery = `search=${value}`;
      this.loadingTable = true;
      this.getOrganizationGoal(`?${this.searchQuery}`);
    },

    async extendAppraisal() {
      try {
        await this.$handlePrivilege(
          "performanceAppraisals",
          "extendAppraisalDeadline"
        );
        this.windowModal = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    percentage(value, total) {
      return Math.round((value / total) * 100);
    },

    commentView(value) {
      if (value !== "") {
        this.commentError = false;
      }
    },

    selected(value) {
      this.cyclesArr.filter((cycleArr) => {
        if (cycleArr.id === value) {
          this.cycleDisableDates.to = new Date(
            new Date(cycleArr.endDate).valueOf() + 1000 * 60 * 60 * 24
          );
        }
        return {};
      });
    },

    endDateSelect() {
      this.endDateError = false;
    },

    payloadDate(value) {
      if (value) {
        const month = this.$DATEFORMAT(new Date(value), "MM");
        const day = this.$DATEFORMAT(new Date(value), "dd");
        return `${day}/${month}`;
      }
      return "";
    },

    closeWindow() {
      this.endDateError = false;
      this.commentError = false;
      this.windowModal = false;
    },

    windowExtension() {
      this.payload.orgId = this.$orgId;
      const currentYear = new Date().getFullYear();
      this.payload.year = currentYear;
      this.payload.cycleId = this.payload.appraisalCycleId;

      if (this.payload.endDate === "") {
        this.endDateError = true;
      }
      if (this.payload.comment === "") {
        this.commentError = true;
      } else if (
        this.payload.endDate !== "" &&
        this.payload.comment !== "" &&
        !this.payload.id
      ) {
        this.payload.endDate = `${this.payload.endDate.getFullYear()}/${
          this.payload.endDate.getMonth() + 1
        }/${this.payload.endDate.getDate()}`;

        this.$_extendContractWindow(this.payload)
          .then((response) => {
            this.$toasted.success(response.data.message, { duration: 5000 });
            this.closeWindow();
          })
          .catch((err) => {
            this.$toasted.error(err.response.data.message, { duration: 5000 });
          });
      }
      if (this.payload.id) {
        this.payload.endDate = `${this.payload.endDate.getFullYear()}/${
          this.payload.endDate.getMonth() + 1
        }/${this.payload.endDate.getDate()}`;

        this.$_updateContractWindow(this.payload)
          .then((response) => {
            this.$toasted.success(response.data.message, { duration: 5000 });
          })
          .catch((err) => {
            this.$toasted.error(err.response.data.message, { duration: 5000 });
          });
      }
    },

    getAppraisalCycle() {
      const today = new Date();

      this.$_getAppraisalCycle(this.appraisalQuery()).then((response) => {
        const appraisal = response.data.AppraisalCycle;

        const apprArray = [];
        appraisal.map((appraisalCycles) => {
          const startDate = `${appraisalCycles.cycle_start_date}`;
          const endDate = `${appraisalCycles.cycle_end_date}`;
          apprArray.push({
            periodStart: `${this.formatDateTime(
              startDate
            )} - ${this.formatDateTime(endDate)}`,
          });
          appraisalCycles.cycles.map((cycle) => {
            const cycleStartDate = new Date(
              this.formatDateTime(cycle.appraisal_starts)
            );

            const cycleEndDate = new Date(
              this.formatDateTime(cycle.appraisal_ends)
            );

            if (today >= cycleStartDate && today <= cycleEndDate) {
              this.cycleId = cycle.id;
            }

            this.flagOptions.push({
              id: cycle.id,
              name: cycle.name,
            });

            this.cyclesArr.push({
              id: cycle.id,
              name: cycle.name,
              endDate: this.formatDateTime(cycle.appraisal_ends),
            });

            this.header.push({
              title: cycle.name,
              value: cycle.name,
            });
            apprArray.push({
              [cycle.name]: `${this.formatDateTime(
                cycle.appraisal_starts
              )} - ${this.formatDateTime(cycle.appraisal_ends)}`,
            });
            return {};
          });
          this.appraisalsArr.push(
            apprArray.reduce((r, i) => Object.assign(r, i), {})
          );
          this.header.push(
            { title: "Status", value: "status" },
            { title: "", value: "id", image: true }
          );
          return {};
        });
        this.getOrganizationGoal("");
        this.contractExtensionsData();
      });
    },

    getCycleName(id) {
      let score;
      this.cyclesArr.filter((appraisal) => {
        if (appraisal.id === id) {
          score = appraisal.name;
        }
        return {};
      });
      return score || "";
    },

    getOrganizationGoal(params) {
      this.shortTermGoals = [];
      const agreement = [];
      this.tableData = [];

      this.$_getOrganizationGoal(params)
        .then((response) => {
          const goalData = response.data.goals;
          this.metaData = response.data.meta;

          const currentYear = new Date().getFullYear();

          if (goalData.length > 0) {
            goalData.forEach((goals) => {
              if (Number(goals.goal.year) === currentYear) {
                this.shortTermGoals.push({
                  id: goals.goal.id,
                  year: goals.goal.year,
                  description:
                    goals.goal.goal_performance_templates.agreement_title,
                  status: "active",
                  cycleId:
                    goals.goal.goal_performance_templates.appraisal_cycle
                      .cycles[0].id,
                  cycleName: this.getCycleName(
                    goals.goal.goal_performance_templates.appraisal_cycle
                      .cycles[
                      goals.goal.goal_performance_templates.appraisal_cycle
                        .cycles.length - 1
                    ].id
                  ),
                });
              } else {
                this.shortTermGoals.push({
                  id: goals.goal.id,
                  year: goals.goal.year,
                  description:
                    goals.goal.goal_performance_templates.agreement_title,
                  status: "closed",
                  cycleId:
                    goals.goal.goal_performance_templates.appraisal_cycle
                      .cycles[0].id,
                  cycleName: this.getCycleName(
                    goals.goal.goal_performance_templates.appraisal_cycle
                      .cycles[0].id
                  ),
                });
              }
              return {};
            });

            if (this.shortTermGoals.length > 0) {
              agreement.push(
                this.appraisalsArr.reduce((r, i) => Object.assign(r, i), {})
              );
              agreement.push(
                this.shortTermGoals.reduce((r, i) => Object.assign(r, i), {})
              );
              agreement.push(
                this.agreementData.reduce((r, i) => Object.assign(r, i), {})
              );

              this.tableData.push(
                agreement.reduce((r, i) => Object.assign(r, i), {})
              );
            }
          }
          this.isLoading = false;
          this.loadingTable = false;
        })
        .catch(() => {
          this.loadingTable = false;
        });
    },

    contractExtensionsData() {
      this.$_getExtensionDocumentation("appraisal").then((response) => {
        const responseData = response.data.data;
        responseData.map((data) => {
          this.payload.appraisalCycleId = data.appraisalCycleId;
          this.payload.year = data.year;
          this.payload.comment = data.comment;
          this.payload.operation = data.operation;
          this.payload.startDate = new Date(data.startDate);
          this.payload.endDate = new Date(data.endDate);
          this.payload.id = data.id;
          return {};
        });
      });
    },
  },

  async mounted() {
    try {
      await this.$handlePrivilege(
        "performanceAppraisals",
        "viewOrganizationAppraisalRegister"
      );
      this.hasModuleAccess = true;
      this.getAppraisalCycle();
    } catch (error) {
      this.hasModuleAccess = false;
    }
  },
};
</script>

<style>
body {
  overflow-x: hidden;
}
.rightSidebar {
  overflow-x: auto;
}
</style>

<style scoped>
.font-size-10 {
  font-size: 10px;
}
</style>
