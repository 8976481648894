<template>
  <div class="w-full">
        <div v-if="hasModuleAccess">
    <div class="">
      <Card class="p-5 pt-0">
        <CardFooter reloadcard @searchResult="handleSearch($event)" showcalendar @reload="reload" />
      </Card>
    </div>
    <div class="w-full h-full p-3" />
    <div style="height: 100%" v-if="isLoading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else>
      <Table
        :headers="header"
        :items="tableData"
        v-if="tableData.length > 0 || loadingTable"
        aria-label="advance Table"
        class="w-full px-0"
        :has-checkbox="false"
        :has-number="false"
        :pagination-list="metaData"
        :page-sync="true"
        @page="handlePage($event)"
        @itemsPerPage="handleItemsPerPage($event)"
        :loading="loadingTable"
      >
        <template v-slot:item="{ item }">
          <div
            v-if="item.year"
            class="font-semibold text-sm leading-5 tracking-widest"
          >
            <span class="text-darkPurple">{{ item.data.year }}</span>
          </div>
          <div
            v-else-if="item.description"
            class="font-semibold text-sm leading-5 tracking-widest"
          >
            <span class="text-darkPurple">{{ item.data.description }}</span>
          </div>
          <div
            v-else-if="item.employees"
            class="font-semibold text-sm leading-5 tracking-widest"
          >
            <div class="flex flex-col justify-start items-start">
              <span class="text-darkPurple">{{ item.data.employees }}</span>
              <div class="text-xs text-romanSilver">
                <span class="text-romanSilver">
                  {{ item.data.submitted }} Submitted</span
                >
                <span
                  :class="{
                    'text-flame':
                      (item.data.submitted / item.data.employees) * 100 < 100,
                    'text-mediumSeaGreen':
                      (item.data.submitted / item.data.employees) * 100 === 100,
                  }"
                >
                  ({{
                    (item.data.submitted / item.data.employees) * 100
                      ? Math.round(
                          (item.data.submitted / item.data.employees) * 100
                        )
                      : "0"
                  }}%)
                </span>
              </div>
            </div>
          </div>
          <div v-else-if="item.status">
            <Badge
              :label="item.data.status"
              variant="primary"
              :background-color="
                item.data.status === 'active'
                  ? 'rgba(19, 181, 106, 0.15)'
                  : 'rgba(135, 142, 153, 0.15)'
              "
              :color="item.data.status === 'active' ? '#13B56A' : '#878E99'"
              class="capitalize"
              style="
                margin-right: 12px;
                font-size: 14px;
                padding: 12px 10px;
                height: 35px;
                border-radius: 5px;
              "
            />
          </div>
          <div v-else-if="item.id" class="cursor-pointer">
                <div
                  class="flex flex-col w-38 h-18 p-2 justify-start items-start"
                >
                  <div
                    class="hover:bg-ghostWhite w-full rounded-md"
                    @click="
                      $router.push({
                        name: 'OrganisationScorecard',
                        params: {
                          year: item.data.year,
                          cycleId: item.data.cycleId,
                        },
                      })
                    "
                  >
                    <div class="flex justify-start items-center p-2 gap-2">
                      <Icon
                        icon-name="icon-eye"
                        class-name="text-blueCrayola"
                        size="xs"
                      />
                    </div>
                  </div>
          </div>
          </div>
        </template>
      </Table>

        <div
          class="flex flex-col justify-center items-center gap-5 px-10 py-10 mt-10"
          v-else
        >
          <icon icon-name="empty_agreement" size="l" style="width: 300px" />
          <p class="font-normal text-base leading-5 text-jet text-center w-96">
            Appraisal Scorecard not available.
          </p>
        </div>

    </div>
  </div>

    <div v-else>
      <ErrorComponent />
    </div>
  </div>
</template>

<script>
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import CardFooter from "@/components/CardFooter";
import paramsMixin from '@/utilities/paramsMixin';
import timeFormatMixin from "@/utilities/timeFormatMixin";
import ErrorComponent from "@/modules/Admin/error403";

export default {
  name: "TDirectReports",
  mixins: [paramsMixin, timeFormatMixin],
  components: {
    Table,
    Card,
    ErrorComponent,
    CardFooter,
    Badge,
  },
  data() {
    return {
      paginationList: {
        page: 1,
        lastPage: 1,
        total: 1,
        from: 1,
        to: 1,
      },
      header: [
        { title: "financial year", value: "year" },
        { title: "description", value: "description" },
        { title: "Period", value: "periodStart" },
      ],
      tableData: [],
      hasModuleAccess: false,
      isLoading: true,
      loadingTable: false,
      employeeLength: "",
      appraisalsArr: [],
      cyclesArr: [],
      shortTermGoals: [],
      agreementData: [],
      windowModal: false,
      endDateError: false,
      commentError: false,
      flagOptions: [],
      cycleId: "",
      payload: {
        orgId: "",
        appraisalCycleId: "",
        year: "",
        operation: "appraisal",
        comment: "",
        endDate: "",
        cycledId: "",
      },
      cycleDisableDates: {
        from: "",
        to: "",
      },
      searchQuery: '',
      metaData: {},
      itemsPerPage: null,
      numberOfPage: null,
    };
  },
  methods: {
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.tableData = [];
      this.queryUpdate();
    },

    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.tableData = [];
      this.queryUpdate();
    },

    queryUpdate() {
      this.loadingTable = true;
      const search = this.searchQuery ? `&${this.searchQuery}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getOrganizationGoal(`?${pageNumber}${itemPage}${search}`);
    },

    handleSearch(value) {
      this.searchQuery = `search=${value}`;
      this.loadingTable = true;
      this.tableData = [];
      this.getOrganizationGoal(`?${this.searchQuery}`);
    },

    reload() {
      this.loadingTable = true;
      this.tableData = [];
      this.getOrganizationGoal('');
    },

    percentage(value, total) {
      return Math.round((value / total) * 100);
    },

    commentView(value) {
      if (value !== "") {
        this.commentError = false;
      }
    },

    selected(value) {
      this.cyclesArr.filter((cycleArr) => {
        if (cycleArr.id === value) {
          this.cycleDisableDates.to = new Date(
            new Date(cycleArr.endDate).valueOf() + 1000 * 60 * 60 * 24
          );
        }
        return {};
      });
    },

    endDateSelect() {
      this.endDateError = false;
    },

    payloadDate(value) {
      if (value) {
        const month = this.$DATEFORMAT(new Date(value), "MM");
        const day = this.$DATEFORMAT(new Date(value), "dd");
        return `${day}/${month}`;
      }
      return "";
    },

    closeWindow() {
      this.endDateError = false;
      this.commentError = false;
      this.windowModal = false;
    },

    windowExtension() {
      this.payload.orgId = this.$orgId;
      const currentYear = new Date().getFullYear();
      this.payload.year = currentYear;
      this.payload.appraisalCycleId = this.payload.cycledId;

      if (this.payload.endDate === "") {
        this.endDateError = true;
      }
      if (this.payload.comment === "") {
        this.commentError = true;
      } else if (
        this.payload.endDate !== "" &&
        this.payload.comment !== "" &&
        !this.payload.id
      ) {
        this.$_extendContractWindow(this.payload)
          .then((response) => {
            this.$toasted.success(response.data.message, { duration: 5000 });
          })
          .catch((err) => {
            this.$toasted.error(err.response.data.message, { duration: 5000 });
          });
      }
      if (this.payload.id) {
        this.$_updateContractWindow(this.payload)
          .then((response) => {
            this.$toasted.success(response.data.message, { duration: 5000 });
          })
          .catch((err) => {
            this.$toasted.error(err.response.data.message, { duration: 5000 });
          });
      }
    },

    getAppraisalCycle() {
      const today = new Date();

      this.$_getAppraisalCycle(this.appraisalQuery()).then((response) => {
        const appraisal = response.data.AppraisalCycle;
        const apprArray = [];

        if(appraisal.length > 0) {
        appraisal.map((appraisalCycles) => {
          const startDate = `${appraisalCycles.cycle_start_date}`;
          const endDate = `${appraisalCycles.cycle_end_date}`;
          apprArray.push({
            periodStart: `${this.formatDateTime(startDate)} - ${this.formatDateTime(
              endDate
            )}`,
          });
          appraisalCycles.cycles.map((cycle) => {
            const cycleStartDate = new Date(
              this.formatDateTime(cycle.appraisal_starts)
            );

            const cycleEndDate = new Date(
              this.formatDateTime(cycle.appraisal_ends)
            );

            if (today >= cycleStartDate && today <= cycleEndDate) {
              this.cycleId = cycle.id;
            }

            this.flagOptions.push({
              id: cycle.id,
              name: cycle.name,
            });

            this.cyclesArr.push({
              id: cycle.id,
              name: cycle.name,
              startDate: this.formatDateTime(cycle.appraisal_starts),
              endDate: this.formatDateTime(cycle.appraisal_ends),
            });

            this.header.push({
              title: cycle.name,
              value: cycle.name,
            });
            apprArray.push({
              [cycle.name]: `${this.formatDateTime(
                cycle.appraisal_starts
              )} - ${this.formatDateTime(cycle.appraisal_ends)}`,
            });
            return {};
          });
          this.appraisalsArr.push(
            apprArray.reduce((r, i) => Object.assign(r, i), {})
          );
          this.header.push(
            { title: "Employees", value: "employees" },
            { title: "Status", value: "status" },
            { title: "", value: "id", image: true }
          );
          return {};
        });
        this.getOrganizationGoal('');
        }
        else {
          this.isLoading = false;
        }
      });
    },

    getOrganizationGoal(params) {
      this.shortTermGoals = [];
      this.$_getOrganizationGoal(params).then((response) => {
        const goalData = response.data.goals;
        this.metaData = response.data.meta;
        const currentYear = new Date().getFullYear();

        if (goalData.length > 0) {
          goalData.forEach((goals) => {
            if (Number(goals.goal.year) === currentYear) {
              this.shortTermGoals.push({
                id: goals.goal.id,
                year: goals.goal.year,
                description:
                  goals.goal.goal_performance_templates.agreement_title,
                status: "active",
                cycleId: this.getActiveCycle(goals.goal.year),
              });
            } else {
              this.shortTermGoals.push({
                id: goals.goal.id,
                year: goals.goal.year,
                description:
                  goals.goal.goal_performance_templates.agreement_title,
                status: "closed",
                cycleId: this.getActiveCycle(goals.goal.year),
              });
            }
            return {};
          });
        }

        if (this.shortTermGoals.length > 0) {
          this.getSubmittedAppraisals();
        }
      });
    },

    getActiveCycle(year) {
      const currentYear = new Date().getFullYear();
      const today = new Date();
      let cycleId;

      if (year === currentYear) {
        this.cyclesArr.forEach((cycle) => {
          const cycleStartDate = new Date(cycle.startDate);

          const cycleEndDate = new Date(cycle.endDate);

          if (today >= cycleStartDate && today <= cycleEndDate) {
            cycleId = cycle.id;
          }
        });
      }

      return cycleId || this.cyclesArr[0].id;
    },

    getSubmittedAppraisals() {
      const agreement = [];

      this.shortTermGoals.map((goals) => {
        const params = `?cycle=${goals.cycleId}&year=${goals.year}`;
        this.$_getOrgSubmittedAppraisal(params).then((response) => {
          const responseData = response.data.data;
          responseData.map((data) => {
            agreement.push({
              employees: data.totalEmployees,
              submitted: data.SubmittedAppraisal,
            });
            return {};
          });

        if (agreement.length > 0) {
          agreement.push(
            this.shortTermGoals.reduce((r, i) => Object.assign(r, i), {})
          );
          agreement.push(
            this.appraisalsArr.reduce((r, i) => Object.assign(r, i), {})
          );
          this.tableData.push(
            agreement.reduce((r, i) => Object.assign(r, i), {})
          );
          }

            this.isLoading = false;
            this.loadingTable = false;

        });

        return {};
      });
    },

    contractExtensionsData() {
      this.$_getExtensionDocumentation("appraisal").then((response) => {
        const responseData = response.data.data;
        responseData.map((data) => {
          this.payload.appraisalCycleId = data.appraisalCycleId;
          this.payload.year = data.year;
          this.payload.comment = data.comment;
          this.payload.operation = data.operation;
          this.payload.startDate = new Date(data.startDate);
          this.payload.endDate = new Date(data.endDate);
          this.payload.id = data.id;
          return {};
        });
      });
    },
  },

 async mounted() {
   try {
     await this.$handlePrivilege(
       "performanceAppraisals",
       "viewOrganizationScorecardRegister"
     );
     this.hasModuleAccess = true;
     this.getAppraisalCycle();
     this.contractExtensionsData();
   }catch (error) {
      this.hasModuleAccess = false;
    }
  },
};
</script>

<style>
body {
  overflow-x: hidden;
}
</style>

<style scoped>
.font-size-10 {
  font-size: 10px;
}
</style>