<template>
  <div class="w-full h-full pt-5">
      <div class="flex">
        <h1 class="text-xl text-left font-extrabold pl-4 mr-8">
          Performance Appraisal
        </h1>
        <Breadcrumb :items="breadcrumbs" />
      </div>
      <div class="m-32" v-if="loading">
        <Loader size="xxs" />
      </div>
      <div v-else>
        <template>
          <div class="w-full p-5 flex mt-1 flex-col">
            <Tabs
              class="mt-6 mx-3"
              :tabs="tabsItems"
              :active-tab="activeTab"
              @currentTab="activeTab = $event"
              :border="true"
            />
            <div style="height: 100%" v-if="loading">
              <loader size="xxs" />
            </div>
            <div v-else class="w-full flex flex-col">
              <TOrganisationalAppraisal
                v-if="activeTab === 'Organization Appraisal'"
              />
              <TEmployeeAppraisal v-if="activeTab === 'Employee Appraisal'" />
              <TOrganisationalScorecard
                v-if="activeTab === 'Organization Scorecard'"
              />
            </div>
          </div>
        </template>
      </div>
  </div>
</template>

<script>
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Loader from "@scelloo/cloudenly-ui/src/components/loader";
import Tabs from "@scelloo/cloudenly-ui/src/components/tab";
import TOrganisationalAppraisal from "./Tabs/TOrganisationalAppraisal";
import TOrganisationalScorecard from "./Tabs/TOrganisationalScorecard";
import TEmployeeAppraisal from "./Tabs/TEmployeeAppraisal";

export default {
  name: "PerformanceAppraisals",
  components: {
    Breadcrumb,
    Tabs,
    Loader,
    TOrganisationalAppraisal,
    TOrganisationalScorecard,
    TEmployeeAppraisal,
  },
  data() {
    return {
      openModal: false,
      hasModuleAccess: false,
      loading: false,
      tabsItems: [
        "Organization Appraisal",
        "Employee Appraisal",
        "Organization Scorecard",
      ],
      activeTab: this.$route.query.activeTab || "Organization Appraisal",
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance",
          href: "Performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Performance Appraisal",
          href: "performance-appraisal",
          id: "PerformanceAppraisal",
        },
      ],
    };
  },
};
</script>
