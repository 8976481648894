var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[(_vm.hasModuleAccess)?_c('div',[_c('div',{},[_c('Card',{staticClass:"p-5 pt-0"},[_c('CardFooter',{attrs:{"reloadcard":"","showcalendar":""},on:{"searchResult":function($event){return _vm.handleSearch($event)},"reload":_vm.reload}})],1)],1),_c('div',{staticClass:"w-full h-full p-3"}),(_vm.isLoading)?_c('div',{staticStyle:{"height":"100%"}},[_c('loader',{attrs:{"size":"xxs","loader-image":false}})],1):_c('div',[(_vm.tableData.length > 0 || _vm.loadingTable)?_c('Table',{staticClass:"w-full px-0",attrs:{"headers":_vm.header,"items":_vm.tableData,"aria-label":"advance Table","has-checkbox":false,"has-number":false,"pagination-list":_vm.metaData,"page-sync":true,"loading":_vm.loadingTable},on:{"page":function($event){return _vm.handlePage($event)},"itemsPerPage":function($event){return _vm.handleItemsPerPage($event)}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [(item.year)?_c('div',{staticClass:"font-semibold text-sm leading-5 tracking-widest"},[_c('span',{staticClass:"text-darkPurple"},[_vm._v(_vm._s(item.data.year))])]):(item.description)?_c('div',{staticClass:"font-semibold text-sm leading-5 tracking-widest"},[_c('span',{staticClass:"text-darkPurple"},[_vm._v(_vm._s(item.data.description))])]):(item.employees)?_c('div',{staticClass:"font-semibold text-sm leading-5 tracking-widest"},[_c('div',{staticClass:"flex flex-col justify-start items-start"},[_c('span',{staticClass:"text-darkPurple"},[_vm._v(_vm._s(item.data.employees))]),_c('div',{staticClass:"text-xs text-romanSilver"},[_c('span',{staticClass:"text-romanSilver"},[_vm._v(" "+_vm._s(item.data.submitted)+" Submitted")]),_c('span',{class:{
                  'text-flame':
                    (item.data.submitted / item.data.employees) * 100 < 100,
                  'text-mediumSeaGreen':
                    (item.data.submitted / item.data.employees) * 100 === 100,
                }},[_vm._v(" ("+_vm._s((item.data.submitted / item.data.employees) * 100 ? Math.round( (item.data.submitted / item.data.employees) * 100 ) : "0")+"%) ")])])])]):(item.status)?_c('div',[_c('Badge',{staticClass:"capitalize",staticStyle:{"margin-right":"12px","font-size":"14px","padding":"12px 10px","height":"35px","border-radius":"5px"},attrs:{"label":item.data.status,"variant":"primary","background-color":item.data.status === 'active'
                ? 'rgba(19, 181, 106, 0.15)'
                : 'rgba(135, 142, 153, 0.15)',"color":item.data.status === 'active' ? '#13B56A' : '#878E99'}})],1):(item.id)?_c('div',{staticClass:"cursor-pointer"},[_c('div',{staticClass:"flex flex-col w-38 h-18 p-2 justify-start items-start"},[_c('div',{staticClass:"hover:bg-ghostWhite w-full rounded-md",on:{"click":function($event){return _vm.$router.push({
                      name: 'OrganisationScorecard',
                      params: {
                        year: item.data.year,
                        cycleId: item.data.cycleId,
                      },
                    })}}},[_c('div',{staticClass:"flex justify-start items-center p-2 gap-2"},[_c('Icon',{attrs:{"icon-name":"icon-eye","class-name":"text-blueCrayola","size":"xs"}})],1)])])]):_vm._e()]}}],null,false,1979760663)}):_c('div',{staticClass:"flex flex-col justify-center items-center gap-5 px-10 py-10 mt-10"},[_c('icon',{staticStyle:{"width":"300px"},attrs:{"icon-name":"empty_agreement","size":"l"}}),_c('p',{staticClass:"font-normal text-base leading-5 text-jet text-center w-96"},[_vm._v(" Appraisal Scorecard not available. ")])],1)],1)]):_c('div',[_c('ErrorComponent')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }